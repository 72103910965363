<template>
    <validation-provider
        ref="provider"
        :rules="rules"
        class="media-uploader--wrapper"
        :class="rootClass"
        :detect-input="false"
    >
        <a-media-input
            ref="uploader"
            :accepted-file-types="acceptedFileTypes"
            :max-file-size="maxFileSizeInBytes"
            :file-size-base="1024"
            :label-idle="labelIdle"
            :server="server"
            :image-edit-editor="editor"
            :allow-revert="false"
            :instant-upload="instantUpload"
            :style-panel-layout="layout"
            style-image-edit-button-edit-item-position="top right"
            v-bind="$attrs"
            v-on="$listeners"
            @processfiles="onAllDone"
            @init="onInit"
            @addfile="onAddFile"
            @removefile="onRemoveFile"
            @activatefile="onPreviewClick"
        />
        <a-image-cropper
            v-if="croppable"
            :id="cropperId"
            :options="cropperOptions"
            :can-rotate-image="canRotateImage"
            :can-rotate-selection="canRotateSelection"
            @apply="applyCrop"
            @data="saveCropData"
            @close="onCropperClose"
        />
    </validation-provider>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { FileOrigin, FilePondFile, FileStatus } from 'filepond';

import { ValidationProvider } from 'vee-validate';

import {
    setValidator,
    unsetValidator
} from '@/plugins/vee-validate/rules/runtime';

import { AMediaInput } from '@/components/AForm/Inputs/AMediaInput';
import AImageCropper from './AImageCropper.vue';

import type { CropperOptions, Instructions } from '.';
import type { MediaFile } from '@/types/MediaFile';
import { AxiosProgressEvent } from 'axios';

const MediaUploaderProps = Vue.extend({
    name: 'AMediaUploader',
    inheritAttrs: false,
    props: {
        acceptedFileTypes: {
            type: String,
            default() {
                return 'image/jpeg, image/png, image/gif';
            }
        },
        maxFileSize: {
            type: String,
            default() {
                return '5 MB';
            }
        },
        instantUpload: {
            type: Boolean,
            default() {
                return false;
            }
        },
        label: {
            type: String,
            default() {
                return '';
            }
        },
        endpoint: {
            type: String,
            default() {
                return '/media/save?resource=1';
            }
        },
        mediaField: {
            type: String,
            default() {
                return 'media_ajax[filename_file]';
            }
        },
        dataField: {
            type: String,
            default() {
                return 'media_ajax[data]';
            }
        },
        options: {
            type: Object as PropType<Record<string, string>>,
            default() {
                return {};
            }
        },
        croppable: {
            type: Boolean,
            default() {
                return false;
            }
        },
        uploadCropped: {
            type: Boolean,
            default() {
                return false;
            }
        },
        canRotateImage: {
            type: Boolean,
            default() {
                return false;
            }
        },
        canRotateSelection: {
            type: Boolean,
            default() {
                return false;
            }
        },
        cropAspectRatio: {
            type: Number,
            default() {
                return 0;
            }
        },
        cropAspectRatioFromImage: {
            type: Boolean,
            default() {
                return false;
            }
        },
        layout: {
            type: String,
            default() {
                return 'compact';
            }
        }
    }
});

type FilePondEditor = {
    open: (file: File, instructions: Instructions) => void;
};

@Component({
    components: {
        AMediaInput,
        AImageCropper,
        ValidationProvider
    }
})
export default class AMediaUploader extends MediaUploaderProps {
    _uid!: number;

    $refs!: {
        provider: InstanceType<typeof ValidationProvider>;
        uploader: InstanceType<typeof AMediaInput>;
    };

    isValid = true;
    isUploading = false;

    blankInstructions = {
        data: {},
        cropbox: {},
        canvas: {},
        zoom: {
            factor: 0,
            value: 0
        }
    };

    cropperOptions: CropperOptions = {
        file: '',
        name: '',
        instructions: { ...this.blankInstructions },
        options: {
            aspectRatio: this.cropAspectRatio
        },
        compression: {
            maxSizeMB: parseInt(this.maxFileSize),
            maxWidthOrHeight: 2560 // as defined in BE, MediaFilesTable::checkMediaDimensions
        },
        cropAspectRatioFromImage: false
    };
    // uploaded results
    uploads: Record<string, string>[] = [];

    original: Record<string, Blob> = {};
    cropData: Record<string, CropperOptions['instructions']['data']> = {};
    lastCropData: Record<string, CropperOptions['instructions']> = {};

    get id() {
        return this._uid;
    }

    get cropperId() {
        return `media-cropper-dialog-${this.id}`;
    }

    get rules() {
        return `uploader:${this.id}`;
    }

    get labelIdle() {
        return (
            this.label ||
            `
                Drop file here / Click to select
                <br />
                <small>Accepted file formats include: GIF, JPG, PNG. Maximum ${this.maxFileSize}.</small>
            `
        );
    }

    get editor(): FilePondEditor | null {
        if (!this.croppable) {
            return null;
        }

        return {
            // Called by FilePond to edit the image
            // - should open your image editor
            // - receives file object and image edit instructions
            open: file => {
                this.openCropperForFile(file);
            }
        };
    }

    get server() {
        return {
            process: this.upload
        };
    }

    get rootClass() {
        const classes = [];

        if (this.croppable) {
            classes.push('croppable');
        }

        if (this.isUploading) {
            classes.push('uploading');
        }

        return classes.join(' ');
    }

    get maxFileSizeInBytes() {
        const base = 1024;

        let naturalFileSize = String(this.maxFileSize).trim();
        // if is value in megabytes
        if (/MB$/i.test(naturalFileSize)) {
            naturalFileSize = naturalFileSize.replace(/MB$i/, '').trim();
            return parseInt(naturalFileSize, 10) * base * base;
        }
        // if is value in kilobytes
        if (/KB/i.test(naturalFileSize)) {
            naturalFileSize = naturalFileSize.replace(/KB$i/, '').trim();
            return parseInt(naturalFileSize, 10) * base;
        }

        return parseInt(naturalFileSize, 10);
    }

    created() {
        setValidator(this.id, () => this.isValid);
    }

    beforeDestroy() {
        unsetValidator(this.id);
    }

    upload(
        fieldName: string,
        file: File | Blob,
        metadata: Record<string, string>,
        load: (message?: string) => void,
        error: (error?: string) => void,
        progress: (
            lengthComputable: boolean,
            loaded: number,
            total: number
        ) => void,
        abort: () => void
    ) {
        const controller = new AbortController();

        const formData = new FormData();

        file =
            file instanceof File
                ? file
                : new File([file], (file as Blob & { name: string }).name);

        formData.append(this.mediaField, file);

        formData.append(this.dataField, this.getOptions(file as File));

        this.$http
            .post(this.endpoint, formData, {
                signal: controller.signal,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress(e: AxiosProgressEvent) {
                    progress(true, e.loaded, e.total || 0);
                }
            })
            .then(({ data }) => {
                if (!data?.meta?.success) {
                    error(data.meta.errors?.filename_file?.uploadedFile ?? '');
                } else {
                    load(data?.data?.data?.id);
                    // collect results, will use when all are done
                    this.uploads.push(data?.data?.data);
                }
            })
            .catch(error);

        // Should expose an abort method so the request can be cancelled
        return {
            abort: () => {
                // This function is entered if the user has tapped the cancel button
                controller.abort();
                // Let FilePond know the request has been cancelled
                abort();
            }
        };
    }

    onUpload(data: Partial<MediaFile>) {
        this.$emit('uploaded', data);
    }

    onAllDone() {
        // add some delay for better UX
        setTimeout(() => {
            if (this.uploads.length) {
                this.onUpload(this.uploads[0]);
            }

            this.reset();

            this.isUploading = false;
        }, 500);
    }

    onAddFile() {
        const files = this.getFiles();

        this.$refs.provider.syncValue(files);

        this.isValid = files.every(item => this.isValidFile(item));
    }

    onRemoveFile() {
        const files = this.getFiles();

        if (!files.length) {
            this.$refs.provider.syncValue(null);

            this.resetData();
        } else {
            this.$refs.provider.syncValue(files);
        }
    }

    onInit() {
        this.$emit('queue', () => this.tryToUpload());
    }

    tryToUpload() {
        const files = this.getFiles();

        const validFiles = files.filter(item => this.isValidFile(item));

        if (validFiles.length) {
            this.isUploading = true;

            return this.$refs.uploader.$refs.pond.processFiles();
        }

        return Promise.resolve();
    }

    isValidFile(file: FilePondFile) {
        return (
            !(
                file.status === FileStatus.IDLE &&
                file.origin === FileOrigin.LOCAL
            ) &&
            file.status !== FileStatus.PROCESSING &&
            file.status !== FileStatus.PROCESSING_COMPLETE &&
            file.status !== FileStatus.PROCESSING_REVERT_ERROR &&
            file.status !== FileStatus.LOAD_ERROR
        );
    }

    reset() {
        this.removeFiles();

        this.resetData();
    }

    resetData() {
        this.uploads = [];

        this.original = {};
        this.cropData = {};
        this.lastCropData = {};
    }

    replaceData(source: string, target: string) {
        this.original[target] = this.original[source];
        this.cropData[target] = this.cropData[source];
        this.lastCropData[target] = this.lastCropData[source];

        this.removeData(source);
    }

    removeData(source: string) {
        delete this.original[source];
        delete this.cropData[source];
        delete this.lastCropData[source];
    }

    removeFiles() {
        this.$refs.uploader?.$refs.pond?.removeFiles();
    }

    setCropperOptions(options: CropperOptions) {
        this.cropperOptions = options;
    }

    applyCrop({
        id,
        original,
        crop
    }: {
        id: string;
        original: Blob;
        crop: Blob;
    }) {
        this.replace(id, original, crop);

        this.closeCropper();
    }

    replace(id: string, source: Blob, target: Blob) {
        this.original[id] = source;

        this.add(id, target)
            .then(added => this.replaceData(id, added.id))
            .catch(({ file }) => this.replaceData(id, file.id));

        this.remove(id);
    }

    add(id: string, file: Blob) {
        return this.$refs.uploader.$refs.pond.addFile(
            new File([file], this.getNewFileNameForId(id, file), {
                type: file.type
            }),
            {
                index: this.getFileIndexById(id)
            }
        );
    }

    remove(id?: string) {
        return this.$refs.uploader.$refs.pond.removeFile(id);
    }

    getFiles() {
        return this.$refs.uploader.$refs.pond.getFiles();
    }

    saveCropData({
        id,
        data
    }: {
        id: string;
        data: CropperOptions['instructions'];
    }) {
        if (!this.uploadCropped) {
            this.cropData[id] = data.data;
        }

        this.lastCropData[id] = data;
    }

    tryOpenCropper(id: string) {
        const file = this.getFileById(id);

        if (file) {
            this.openCropperForFile(file.file as File);
        }
    }

    openCropperForFile(file: File) {
        this.setCropperOptions(this.getCropperOptions(file));

        this.openCropper();
    }

    openCropper() {
        this.$store.dispatch('modal/open', this.cropperId);
    }

    closeCropper() {
        this.$store.dispatch('modal/close', this.cropperId);

        this.onCropperClose();
    }

    onCropperClose() {
        if (this.cropperOptions.file) {
            this.releaseCropperFile(this.cropperOptions.file);
        }
    }

    getOptions(file: File) {
        const id = this.getFileId(file);

        const options: { media_resource?: { [key: string]: string } } =
            JSON.parse(JSON.stringify(this.options)); // deep clone options

        if (!this.uploadCropped && this.cropData) {
            if (!options.media_resource) {
                options.media_resource = {};
            }

            let key: keyof CropperOptions['instructions']['data'];

            for (key in this.cropData[id]) {
                options.media_resource[`crop_${key}`] = String(
                    this.cropData[id][key]
                );
            }
        }

        return JSON.stringify(options);
    }

    getFileId(file: File) {
        const match = this.getFiles().find(f => f.file === file);

        return match?.id || '';
    }

    getFileIndexById(id: string) {
        return this.getFiles().findIndex(file => file.id === id);
    }

    getFileById(id: string) {
        return this.getFiles().find(file => file.id === id);
    }

    getNewFileNameForId(id: string, file: Blob) {
        const original = this.getFileById(id);

        let ext = file.type.split('/').pop();

        if (ext === 'jpeg') {
            ext = 'jpg';
        }

        return [original?.filenameWithoutExtension, ext].join('.');
    }

    getCropperInstructions(id: string) {
        return this.lastCropData[id] || { ...this.blankInstructions };
    }

    getCropperJsOptions(id: string) {
        if (this.lastCropData[id]) {
            // last data has crop already applied
            return {
                aspectRatio:
                    Number(this.lastCropData[id].cropbox.width) /
                    Number(this.lastCropData[id].cropbox.height)
            };
        }

        return {
            ...this.cropperOptions.options
        };
    }

    getCropperOptions(file: File) {
        const id = this.getFileId(file);

        return {
            id,
            file: this.prepareCropperFile(this.original[id] || file),
            name: file.name,
            instructions: this.getCropperInstructions(id),
            options: this.getCropperJsOptions(id),
            compression: this.cropperOptions.compression,
            cropAspectRatioFromImage: this.cropAspectRatioFromImage
                ? this.lastCropData[id]
                    ? false
                    : true
                : false
        };
    }

    getFilePreviewElementById(id: string) {
        return this.$refs.uploader.$el.querySelector(`#filepond--item-${id}`);
    }

    markFileValid(id: string, isValid = true) {
        const filePreviewElement = this.getFilePreviewElementById(id);

        if (filePreviewElement) {
            const className = 'file-invalid';

            if (isValid) {
                filePreviewElement.classList.remove(className);
            } else {
                filePreviewElement.classList.add(className);
            }
        }
    }

    onPreviewClick({ edit }: { edit: () => void }) {
        if (this.croppable && !this.isUploading && typeof edit === 'function') {
            edit();
        }
    }

    prepareCropperFile(source: Blob) {
        return URL.createObjectURL(source);
    }

    releaseCropperFile(source: string) {
        URL.revokeObjectURL(source);
    }
}
</script>

<style lang="scss">
.media-uploader--wrapper {
    width: 100%;
    height: 100%;

    .file-invalid {
        .filepond--file-wrapper:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 5px solid $error;
            border-radius: 0.45em;
            z-index: 3;
            pointer-events: none;
        }
    }

    &.croppable:not(.uploading) .filepond--image-preview-wrapper {
        cursor: pointer;
    }
    // leave the space for uploading indicator
    &.uploading .filepond--file-action-button.filepond--action-edit-item {
        visibility: hidden;
    }
}
</style>
